import React, { useState, useEffect, useCallback } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ErrorMessages } from "../../utility/Messages";
import { postPendoMessageToDossier } from "../../services/pendoService";
import { pendoFeatureFlag } from "../../utility/launchDarklyFlagHelper";
import {
  addDossierErrorHandler,
  createDossier,
  createInstanceForAccounting,
  destroyDossier,
  getDossierProperties,
  OneViewDossierConfig,
  registerEvent,
  setVisualizationNormalSizeForReport,
  storeCurrentInstanceId,
} from "../../services/dossierService";
import { DossierEventType, VisualSize } from "../../enum";
import getError, { ErrorModel } from "../../utility/errorHelper";
import { getReportById } from "../../services/userSettingsService";

const Dossier: React.FC<any> = ({
  containerId,
  dossierId,
  authToken,
  style,
  pageKey,
  visualId,
  size,
  resizeButtonVisible,
  onGraphicSelected,
  mstrLink,
  postAccountingMessage,
  isAccountingReport,
  isAccountingDashboard,
  isBookmark,
}) => {
  const [error, setError] = useState<ErrorModel | undefined>(undefined);
  const isVisualMaximizedDashboard = size === VisualSize.Maximized;
  const navigate = useNavigate();
  const launchDarklyFlags = useFlags();

  const errorHandler = useCallback((error: any) => {
    console.log(`Error in MicroStrategy report loading`);
    console.log(error);
    setError(getError(ErrorMessages.mstrReportLoadError));
  }, []);

  const sessionErrorHandler = useCallback((error: any) => {
    console.log(`MicroStrategy Session error`);
    console.log(error);
    setError(getError(ErrorMessages.sessionError));
  }, []);

  const pageSwitchHandler = useCallback((data: { key: string }) => {
    console.log("Page changed ", data);

    const report = getReportById(dossierId);
    
    const isRestrictedPage = report?.restrictedPages
                        ?.some(pageKey => pageKey.search(data.key) >= 0) 
                        ?? false;
    if(!isRestrictedPage) { return; }

    setError(getError(`You do not have access to this page ${data.key}. Please contact administrator.`));

  }, [dossierId]);

  useEffect(() => setError(undefined), []);

  useEffect(() => {
    if (!error) {
      return;
    }

    const path = "/error";
    const options: NavigateOptions = {
      state: { error },
    };

    navigate(path, options);
  }, [error, navigate]);

  useEffect(() => {
    const placeholder = document.getElementById(containerId);
    if (!placeholder) {
      return;
    }

    initializeDossier(placeholder);
    return cleanup;

    async function initializeDossier(placeholder: HTMLElement) {
      try {
        const config: OneViewDossierConfig = {
          authToken,
          dossierId,
          errorHandler,
          sessionErrorHandler,
          pageKey,
          visualId,
          placeholder,
          isNormalSize: !isVisualMaximizedDashboard,
          isAccountingDashboard,
          isAccountingReport,
          isBookmark,
          mstrLink,
        };

        await CreateInstanceIfRequired(config);
        const dossierProps = getDossierProperties(config);
        const instance = await createDossier(dossierProps);
        setVisualizationNormalSizeForReport(instance, config);
        addDossierErrorHandler(instance, errorHandler);
        registerEvent(instance, pageSwitchHandler , DossierEventType.OnPageChanged);
        
        if (onGraphicSelected)
          registerEvent(
            instance,
            onGraphicSelected,
            DossierEventType.GraphicSelecetd
          );
        performPostInsanceloadOperationsIfRequired(instance, config);
      } catch (error) {
        console.log("Encounter error while initializing the dossier", error);
      }

      function performPostInsanceloadOperationsIfRequired(
        instance: any,
        config: OneViewDossierConfig
      ) {
        if (launchDarklyFlags[pendoFeatureFlag]) {
          postPendoMessageToDossier(containerId);
        }
        if ((isAccountingDashboard || isAccountingReport) && !isBookmark) {
          registerEvent(
            instance,
            postAccountingMessage,
            DossierEventType.OnLayoutChanged
          );
          storeCurrentInstanceId(instance, config);
        }
      }

      async function CreateInstanceIfRequired(config: OneViewDossierConfig) {
        if ((isAccountingDashboard || isAccountingReport) && !isBookmark) {
          const instanceId = await createInstanceForAccounting(config);
          config.instanceId = instanceId;
        }
      }
    }

    function cleanup() {
      destroyDossier(placeholder);
    }
  }, [
    dossierId,
    authToken,
    containerId,
    visualId,
    isVisualMaximizedDashboard,
    resizeButtonVisible,
    onGraphicSelected,
    pageKey,
    errorHandler,
    sessionErrorHandler,
    isAccountingDashboard,
    isAccountingReport,
    isBookmark,
    launchDarklyFlags,
    postAccountingMessage,
    mstrLink,
    pageSwitchHandler
  ]);

  if (error) return <></>;

  const updatedStyle: React.CSSProperties = {
    display: authToken ? "flex" : "none",
    flexGrow: 1,
    height: "100%",
    ...style,
  };

  return (
    <>
      <div
        id={containerId}
        style={updatedStyle}
        //@ts-ignore
        sandbox="allow-same-origin allow-scripts"
      />
    </>
  );
};

export default Dossier;
