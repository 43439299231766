import { currentEnvironment } from "../environments/environments";
import { UserAccount } from "../model/userAccountModel";
import { UserReport } from "../model/userReportsModel";
import {
  setUserReports,
  getUserReports as getUserReportsFromSession,
  clearUserReports as clearUserReportsFromSession,
  setUserAccount,
  getUserAccount as getUserAccountFromSession,
  clearUserAccount as clearUserAccountFromSession,
} from "../utility/sessionStorageHelper";
import { UserSettingsApiService } from "./apiService";

export function InitUserSettings() {
  const environment = currentEnvironment();
  UserSettingsApiService.get(
    `${environment.customConfiguration.userSettingUrl}/init`
  );
}

export async function getUserReports(
  token: string
): Promise<UserReport[] | undefined> {
  try {
    const environment = currentEnvironment();
    const path = `${environment.customConfiguration.userSettingUrl}/user/reports`;
    const response = await UserSettingsApiService.get(path, token);

    if (!response?.ok) {
      console.log("api call to setting api has failed.");
      return;
    }

    const userReports = (await response.json()) as UserReport[];
    
    userReports.forEach(report => {
      if(!restrictedPages.has(report.reportId)) { return; }

      report.restrictedPages = [...restrictedPages.get(report.reportId)!];
    })

    setUserReports(userReports);

    return userReports;
  } catch (e) {
    console.log(e);
  }
  return undefined;
}

export function getReports(): UserReport[] {
  return getUserReportsFromSession();
}

export function getReportById(id: string): UserReport | undefined {
  const reports =  getUserReportsFromSession();
  return reports.find(r => r.reportId === id);
}

export function clearUserReports() {
  clearUserReportsFromSession();
}

export function getUserReportByReportId(
  reportId: string
): UserReport | undefined {
  return getReports().find((f) => f.reportId === reportId);
}

export async function getUserAccount(
  token: string
): Promise<UserAccount | undefined> {
  try {
    const environment = currentEnvironment();
    const path = `${environment.customConfiguration.userSettingUrl}/user/account`;
    const response = await UserSettingsApiService.get(path, token);

    if (!response?.ok) {
      console.log("api call to setting api to get user account has failed.");
      return;
    }

    const userAccount = (await response.json()) as UserAccount;
    setUserAccount(userAccount);

    return userAccount;
  } catch (e) {
    console.log(e);
  }
  return undefined;
}

export function getAccount(): UserAccount | undefined {
  return getUserAccountFromSession();
}

export function clearUserAccount() {
  clearUserAccountFromSession();
}


const restrictedPages: Map<string, string[]> = new Map(
  [
    ["B6A42F5E2B48412B514D54BCAFFCA3C4", [ "W217--K46", "W221--K46", "W244--K265"] ],
    ["BF4F8FBF334AD793B99AEF80498E533D", ["W137--K46"]],
    ["A412BA867E4181ED104CEF8B88C5355D", ["W377--K46", "W427--K111" ]]
  ]);

/*
const data: UserReport[] = [
  {
    "reportId": "005457D3504815923CDE38B640BBD166",
    "reportName": "Revenue and Profit",
    "reportGroupName": "Service",
    "pageKey": "K53",
    "visualKey": "W131",
    "visualRows": 1,
    "visualCols": 3,
    "groupOrder": 1,
    "reportOrder": 1,
    "dashboardId": "B2D0778B3C4184AAA541189F4D82E7DC"
  },
  {
    "reportId": "B6A42F5E2B48412B514D54BCAFFCA3C4",
    "reportName": "Service Advisor",
    "reportGroupName": "Service",
    "pageKey": "K53",
    "visualKey": "W85",
    "visualRows": 2,
    "visualCols": 3,
    "groupOrder": 1,
    "reportOrder": 2,
    "dashboardId": "B2D0778B3C4184AAA541189F4D82E7DC",
    "restrictedPages": [
      "W217--K46",
      "W221--K46",
      "W244--K265"
    ]
  },
  {
    "reportId": "BF4F8FBF334AD793B99AEF80498E533D",
    "reportName": "Technician",
    "reportGroupName": "Service",
    "pageKey": "K53",
    "visualKey": "W131",
    "visualRows": 1,
    "visualCols": 3,
    "groupOrder": 1,
    "reportOrder": 3,
    "dashboardId": "B2D0778B3C4184AAA541189F4D82E7DC",
    "restrictedPages": [
      "W137--K46",
    ]
  },
  {
    "reportId": "386D68FF0B48C1FCE6806DB66F5E424D",
    "reportName": "Appointments",
    "reportGroupName": "Service",
    "pageKey": "K53",
    "visualKey": "W99",
    "visualRows": 1,
    "visualCols": 1,
    "groupOrder": 1,
    "reportOrder": 4,
    "dashboardId": "B2D0778B3C4184AAA541189F4D82E7DC",
    "restrictedPages": [
      "W85--K46"
    ]
  },
  {
    "reportId": "A412BA867E4181ED104CEF8B88C5355D",
    "reportName": "Sales",
    "reportGroupName": "Sales",
    "pageKey": "K53",
    "visualKey": "W131",
    "visualRows": 1,
    "visualCols": 3,
    "groupOrder": 2,
    "reportOrder": 1,
    "dashboardId": undefined,
    "restrictedPages": [
      "W377--K46",
      "W427--K111"
    ]
  },
  {
    "reportId": "261C29ABCC456F7E55336BAA30F65AFA",
    "reportName": "Accounting",
    "reportGroupName": "Accounting",
    "pageKey": "K53",
    "visualKey": "W131",
    "visualRows": 1,
    "visualCols": 3,
    "groupOrder": 3,
    "reportOrder": 1,
    "dashboardId": undefined
  }
]
*/