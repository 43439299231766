export enum VisualSize {
  Normal = "normal",
  Maximized = "maximized",
}

export enum MstrMessageType {
  Pendo = "pendo",
  AccountingSummaryToggle = "accountSummaryToggle",
}

export enum DossierEventType {
  GraphicSelecetd = 1,
  OnLayoutChanged,
  OnPageChanged,
}
