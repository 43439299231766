import { useEffect, useContext, useState } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  getUserReportByReportId
} from "../../services/userSettingsService";
import useCustomSearchParams from "../../utility/useCustomSearchParam";
import ReportContainer from "../visuals/reportContainer/ReportContainer";
import { getLaunchDarklyFlagKeyFromReportId } from "../../utility/launchDarklyFlagHelper";
import { ErrorMessages } from "../../utility/Messages";
import NoAccess from "../controls/noAccess/NoAccess";
import { AdminUserContext } from "../../contexts";
import getError from "../../utility/errorHelper";

function ReportPage() {
  const reportId = useCustomSearchParams("id");
  const report = reportId ? getUserReportByReportId(reportId) : undefined;
  const visualId = report?.visualKey;
  const pageKey = report?.pageKey;
  const mstrLink = useCustomSearchParams("mstrLink") ?? undefined;
  const navigate = useNavigate();
  const launchDarklyFlags = useFlags();
  const isAdminUser = useContext(AdminUserContext);
  const [pageAccess, setPageAccess] = useState(false);

  useEffect(() => {
    if (!reportId) {
      navigate("/error", {
        state: {
          error: getError(ErrorMessages.incorrectResource),
        },
      });
    }
  }, [navigate, reportId]);

  useEffect(() => {
    if (!reportId || !mstrLink?.length) {
      setPageAccess(true);
      return;
    }
    const report = getUserReportByReportId(reportId)!;

    const key = report.restrictedPages?.find(
      (key) => mstrLink.search(key) >= 0
    );
    if (!key?.length) {
      setPageAccess(true);
      return;
    }

    const error = getError(
      `you do not have access to this page ${mstrLink}. Please contact you administrator.`
    );
    const path = "/error";
    const options: NavigateOptions = {
      state: { error },
    };
    navigate(path, options);
    return;
  }, [navigate, mstrLink, reportId]);

  if (!reportId)
    return <NoAccess msg={ErrorMessages.noCurrentReportAccess}></NoAccess>;

  const hasReportAccess =
    getLaunchDarklyFlagKeyFromReportId(reportId) &&
    launchDarklyFlags[getLaunchDarklyFlagKeyFromReportId(reportId)!] &&
    pageAccess;

  if (isAdminUser || hasReportAccess)
    return (
      <ReportContainer
        dossierId={reportId}
        visualId={visualId}
        pageKey={pageKey}
        mstrLink={mstrLink}
      />
    );

  return <NoAccess msg={ErrorMessages.noCurrentReportAccess}></NoAccess>;
}

export default ReportPage;
